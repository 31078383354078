import React from "react";
import HTML from "../../assets/img/skills/1.png";
import CSS from "../../assets/img/skills/2.png";
import JS from "../../assets/img/skills/3.png";
import REACT from "../../assets/img/skills/4.png";
import WP from "../../assets/img/skills/5.png";
import IONIC from "../../assets/img/skills/6.png";
import NG from "../../assets/img/skills/7.png";
import PHP from "../../assets/img/skills/8.png";
//import { textAlign } from "@mui/system";


const skillsContent = [
  { skillClass: "p85", skillPercent: "85", skillName: "HTML", skillIcon: HTML },
  { skillClass: "p75", skillPercent: "75", skillName: "CSS", skillIcon: CSS },
  { skillClass: "p65", skillPercent: "65", skillName: "JAVASCRIPT", skillIcon: JS },
  { skillClass: "p60", skillPercent: "55", skillName: "REACT", skillIcon: REACT },
  { skillClass: "p70", skillPercent: "75", skillName: "WORDPRESS", skillIcon: WP },
  { skillClass: "p50", skillPercent: "50", skillName: "IONIC", skillIcon: IONIC },
  { skillClass: "p45", skillPercent: "45", skillName: "ANGULAR", skillIcon: NG },
  { skillClass: "p35", skillPercent: "35", skillName: "PHP", skillIcon: PHP },
];


const Skills = () => {
  return (   
    <>
    <style>
        {
          `#skills {
                text-align:center
            },
            #icon {
                width: 40px
                height: 40px
            }`
        }
      </style>
      {skillsContent.map((val, i) => (
        <div id="skills" className="col-6 col-md-3 mb-3 mb-sm-5" key={i}>
          <div className={`c100 ${val.skillClass}`}>
            <span><img id="icon" src={val.skillIcon} alt="icon"></img></span>
            <div className="slice">
              <div className="bar"></div>
              <div className="fill"></div>
            </div>
          </div>
          <h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
            {val.skillName}
          </h6>
            </div>
      ))}
    </>
  );
};
export default Skills;

//<span>{val.skillPercent}%</span>
